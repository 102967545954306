import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Email from '../components/Email'
import ContactForm from '../components/ContactForm'
import { initI18n } from '../translations'

const Contact = props => {
  const i18n = initI18n(props.pageContext.instance, props.pageContext.lang)
  return (
    <Layout {...props}>
      <div className="main-content content-module contact">
        <Container>
          <h1>{i18n('contact.heading')}</h1>

          <div className="columns">
            <div>
              <p>{i18n('contact.notice')}</p>
              <ContactForm
                i18n={i18n}
                instance={props.pageContext.instance}
                lang={props.pageContext.lang}
              />
            </div>

            <div>
              <h2>{i18n('contact.address')}</h2>
              <p>{i18n('layout.address')}</p>

              <h2>{i18n('contact.email')}</h2>
              <p>
                <Email email={i18n('layout.email')} />
              </p>

              <h2>{i18n('contact.phone')}</h2>
              <p>{i18n('layout.phone')}</p>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Contact
