import React from 'react'

import './ContactForm.css'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: false,
      formError: false,
      form: {},
    }
  }

  handleChange = e => {
    const form = { ...this.state.form, [e.target.name]: e.target.value }
    this.setState({ form })
  }

  handleSubmit = e => {
    e.preventDefault()
    fetch(`/${this.props.instance.id}/${this.props.lang}/contact`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': e.target.getAttribute('name'),
        ...this.state.form,
      }),
    })
      .then(() => {
        this.setState({ formSubmitted: true })
        window.scrollTo(0, 0)
      })
      .catch(() => {
        this.setState({ formError: true })
        window.scrollTo(0, 0)
      })
  }

  formIsValid = () => {
    const { form } = this.state
    return !!(
      form.company &&
      form.company.length &&
      form.name &&
      form.name.length &&
      form.phone &&
      form.phone.length &&
      form.email &&
      form.email.length
    )
  }

  render() {
    const { i18n, instance, lang } = this.props

    if (this.state.formSubmitted) {
      return <p>{i18n('contact.submitted')}</p>
    }

    if (this.state.formError) {
      return <p>{i18n('contact.error')}</p>
    }

    return (
      <React.Fragment>
        <form
          method="POST"
          name={`contact-${instance.id}`}
          action={`/${instance.id}/${lang}/contact`}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <div className="hidden">
            <input name="bot-field" onChange={this.handleChange} />
          </div>
          <div>
            <label>
              {i18n('contact.company')}: *
              <input
                type="text"
                name="company"
                onChange={this.handleChange}
                required
              />
            </label>
          </div>
          <div>
            <label>
              {i18n('contact.name')}: *
              <input
                type="text"
                name="name"
                onChange={this.handleChange}
                required
              />
            </label>
          </div>
          <div>
            <label>
              {i18n('contact.phone')}: *
              <input
                type="text"
                name="phone"
                onChange={this.handleChange}
                required
              />
            </label>
          </div>
          <div>
            <label>
              {i18n('contact.email')}: *
              <input
                type="text"
                name="email"
                onChange={this.handleChange}
                required
              />
            </label>
          </div>
          <div>
            <label>
              {i18n('contact.comment')}:
              <textarea name="comment" rows={5} onChange={this.handleChange} />
            </label>
          </div>
          <div>
            <button type="submit" disabled={!this.formIsValid()}>
              {i18n('contact.submit')}
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

export default ContactForm
